import { tss } from 'tss-react';
import React from 'react';
import styles from "./styles";
import Link from "@mui/material/Link";
import SliderForm from "../Shared/Slider/SliderForm";
import Button from "@mui/material/Button";
import clsx from "clsx";

import RailsRoutes from '../Shared/RailsRoutes';
import WebsaUITheme from '../Shared/WebsaUITheme';
import ThemeProvider from '../Shared/ThemeProvider';
import Copyright from "../Shared/Copyright/Copyright";

export default function Services(props) {
  const theme = ThemeProvider(props.theme);
  const { classes } = tss.create(styles(theme))();

  return (
    <WebsaUITheme theme={props.theme}>
      <div className={classes.root}>
        <div className={classes.slider}>
          <SliderForm/>
        </div>
        <div className={classes.form}>
          <div className={classes.head}>
            <p>
              Sign in as a different user? <Link rel="stylesheet" data-method="DELETE" href={RailsRoutes.destroy_user_session_path()}>Sign out</Link>
            </p>
          </div>
          <div className={clsx( classes.tagForm, classes.autoWidth )}>
            <div className={classes.root}>
              <div className={clsx( classes.loginForm, classes.long )}>
                <h3 className="textStylesh2Default">
                  Hello, {props.username}
                  <p className="textStylesInfoBlockDescription">
                    You can use these panels to manage Websa applications:
                  </p>
                </h3>
                <div className={classes.buttonLinkContainer}>
                  {
                    props.admin_panels.map(panel => {
                      return (
                        <div>
                          <Button variant="outlined" color="primary" href={panel.uri}>
                            {panel.name}
                          </Button>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          <Copyright/>
        </div>
      </div>
    </WebsaUITheme>
  );
}
